<template>
<div>
  <div class="container">
    <div class="header"><router-link to="/myrecord"><img src="../assets/images/back.png" alt=""></router-link> 详情</div>
    <div class="blian22"></div>
  </div>
  <div class="recordcont recordcont-books">
    <div class="title"><em></em>我的图书信息</div>
    <ul>
      <li v-for="(item,index) in detail.info" :key="index">
        <h3 style="margin-bottom: 0;height: 100%;">{{ item }}</h3>
        <!--<p>￥350.00</p>-->
      </li>
    </ul>
  </div>  
  <div class="recordcont ">
    <div class="title"><em></em>我的上传凭证</div>
    <div class="recordcont-evidence">
      <img :src="item" alt="" v-for="(item,index) in imgArr" :key="index">
    </div>
  </div>
  <div class="recordcont ">
    <div class="title"><em></em>我的邮寄信息</div>
    <div class="recordcont-mail">
      <p>{{ detail.receiveName }}</p>
      <p>{{ detail.receivePhone }}</p>
      <p>{{ detail.receiveAddr }}</p>
    </div>
  </div>  
  <div class="recordcont ">
    <div class="title"><em></em>您想要的赠书信息</div>
    <div class="recordcont-mail" v-for="(item,index) in detail.sendInfo" :key="index">
      <p>{{ item }}</p>
    </div>
  </div>
    
  <div class="recordcont ">
    <div class="title"><em></em>审核结果  <span v-if="detail.checkStatus==3">未通过</span> <span class="color-54CD5A" v-if="detail.checkStatus==2">已通过</span>  <span class="color-FFAE00" v-if="detail.checkStatus==1">审核中</span></div>
    <div class="recordcont-audit" v-if="detail.checkStatus==3">未通过原因：{{ detail.rejectReason }}</div>
  </div>
</div>
</template>
<script>
	import { applyDetail } from "@/assets/js/api";
export default {
  name: "myrecordcont",
  data() {
    return {
    	imgArr:[],
    	detail:{}
    }
  },
  methods: {
  	getapplyDetail(){
				applyDetail({applyId:this.$route.query.id}).then(res => {
					if(res.code == 0) {
						this.detail = res.detail;
						this.imgArr = res.detail.receipt.split(',')
					}
				});
			}
  },
  mounted() {
  	this.getapplyDetail()
  }
};
</script>

